<template>
    <div class="lottery_rule">
        <van-tag mark type="warning" size="large">注意事项</van-tag>
        <p>1、霸王餐活动是店主发起的到店免费体验项目</p>
        <p>2、参与霸王餐的达人仅限与店铺地址同城</p>
        <p>3、达人中奖霸王餐后需在14天内到店并使用霸王餐，14天后将失效</p>
        <p>4、到店后需联系店主，并出示中奖信息。到店体验后，需写消费后点评</p>
        <p>5、中奖后的达人，将受到公众号消息通知。或在中奖公告查看中奖信息</p>
        <p>6、来飞网是一个培训店主如何做店铺运营的平台。店主可以学习课程，召回客户补发点评，发布霸王餐活动。平台仅提供信息发布的网络技术服务，如因发布违规内容造成账号降权，我司不承担相应责任</p>
    </div>
</template>

<script>
    export default {
        name: "LotteryRule"
    }
</script>

<style scoped>
    .lottery_rule{
        padding-top: 30px;
    }
    .lottery_rule p{
        padding: 0 15px;
    }
</style>